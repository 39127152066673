<template>
   <main v-if="dados != null">
      <section class="checkout-area py-md-5 py-3">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
                  <div class="checkbox-form">
                     <h3 class="mb-0">Meus dados
                        <span class="float-end cursor-pointer" @click="toggleEditar = false;" title="Fechar edição" v-if="toggleEditar">
                           <i class="fas fa-times font-20 mt-2"></i>
                           <span class="d-none d-xl-inline-block font-15 transform-2 ms-2"> Voltar</span>
                        </span>
                        <span class="float-end cursor-pointer" @click="toggleEditar = true;" title="Abrir edição" v-else>
                           <i class="fas fa-pen font-16 mt-2"></i>
                           <span class="d-none d-xl-inline-block font-15 transform-1 ms-2"> Editar</span>
                        </span>
                     </h3>
                     <div class="row">
                        <div class="col-12" v-if="toggleEditar">
                           <div class="your-order-table table-responsive">
                              <table>
                                 <tbody>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="fal fa-id-card color-theme font-12 me-1"></i> CPF</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-envelope color-theme font-12 me-1"></i> Email</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.email" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-road color-theme font-12 me-1"></i> Rua</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.rua" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-map-marker-alt color-theme font-12 me-1"></i> Número</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.numero" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-map-marked color-theme font-12 me-1"></i> Bairro</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.bairro" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-location-circle color-theme font-12 me-1"></i> CEP</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.cep" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-location color-theme font-12 me-1"></i> Ponto de Refêrencia</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.pontoDeReferencia" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="fab fa-whatsapp color-theme font-12 me-1"></i> WhatsApp</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.telefone" v-mask="'(##) #####-####'" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-address-book color-theme font-12 me-1"></i> Nome para contato</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto py-2">
                                          <input type="text" class="form-control font-14" maxlength="250" v-model="dadosEdit.nomeContato" />
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-100 product-total text-end">
                                          <button class="btn bg-theme font-14" @click="salvar">Salvar</button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div class="col-12" v-else>
                           <div class="your-order-table table-responsive">
                              <table>
                                 <tbody>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-user color-theme font-12 me-1"></i> Nome de usuário</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.login }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-male color-theme font-12 me-1"></i> Nome completo</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.nomeCompleto }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="fal fa-id-card color-theme font-12 me-1"></i> CPF</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.cpf }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-envelope color-theme font-12 me-1"></i> Email</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.email }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-road color-theme font-12 me-1"></i> Rua</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.rua }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-map-marker-alt color-theme font-12 me-1"></i> Número</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.numero }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-map-marked color-theme font-12 me-1"></i> Bairro</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.bairro }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-location-circle color-theme font-12 me-1"></i> CEP</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.cep }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-location color-theme font-12 me-1"></i> Ponto de Refêrencia</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.pontoDeReferencia }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="fab fa-whatsapp color-theme font-12 me-1"></i> WhatsApp</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.telefone }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-50 product-name regulaTexto">
                                          <strong class="product-quantity"><i class="far fa-address-book color-theme font-12 me-1"></i> Nome para contato</strong>
                                       </td>
                                       <td class="w-50 product-total regulaTexto text-end">
                                          <span class="amount">{{ dados.user.nomeContato }}</span>
                                       </td>
                                    </tr>
                                    <tr class="cart_item">
                                       <td class="w-100 product-total text-center text-lg-end">
                                          <button class="btn bg-danger text-white font-14" @click="deslogar">
                                             <i class="fas fa-sign-out font-12 me-1"></i> Deslogar
                                          </button>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="checkbox-form">
                     <h3 class="mb-0" v-if="compra.toggle">Compras
                        <span class="float-end cursor-pointer" @click="compra.toggle = false;" title="Fechar compra">
                           <i class="fas fa-times font-20 mt-2"></i>
                           <span class="d-none d-xl-inline-block font-15 transform-2 ms-2"> Voltar</span>
                        </span>
                     </h3>
                     <h3 class="mb-0" v-else>Compras</h3>
                     <div class="row">
                        <div class="col-12">
                           <div class="your-order-table table-responsive">
                              <table>
                                 <tbody v-if="compra.toggle">
                                    <produto v-for="(produto, index) in compra.info.produtos" :key="index" :produto="produto" :index="index" :page="'PERFIL'" />

                                    <div class="cart__item d-flex justify-content-between align-items-center" v-if="compra.info.produtos.length == 0">
                                       <div class="cart__inner d-flex mx-auto">
                                          <div class="cart__details">
                                             <h6 class="my-5"><a href="javascript:;">Nenhum produto encontrado!</a></h6>
                                          </div>
                                       </div>
                                    </div>
                                 </tbody>

                                 <tbody v-else>
                                    <compra v-for="(compra, index) in dados.compras" :key="index" :compra="compra" @verCompra="verCompra($event)" />
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import compra from '@/components/perfil/Compra.vue'
import produto from '@/components/home/Carrinho.vue'

export default {
	name: 'Perfil',
   data: function() {
		return {
			dados: null,
			dadosEdit: {},
         toggleEditar: false,
         compra: {'info': null, 'toggle': false}
		}
	},
   computed: {
		... mapState({
			dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest
		})
	},
   watch: {
      toggleEditar : function (value) {
         if (value && this.dados != null) {
            this.dadosEdit.cpf = this.dados.user.cpf
            this.dadosEdit.email = this.dados.user.email
            this.dadosEdit.rua = this.dados.user.rua
            this.dadosEdit.numero = this.dados.user.numero
            this.dadosEdit.bairro = this.dados.user.bairro
            this.dadosEdit.cep = this.dados.user.cep
            this.dadosEdit.pontoDeReferencia = this.dados.user.pontoDeReferencia
            this.dadosEdit.telefone = this.dados.user.telefone
            this.dadosEdit.nomeContato = this.dados.user.nomeContato
         }
      }
   },
   components: {
		produto, compra
	},
   methods: {
      verCompra : function (compra) {
         this.compra.info = compra
         this.compra.toggle = true
      },
      buscarUsuario : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'user/getuser',
            params: {
               idUser: this.dadosUsuario.cliente.id
            }
         }).then(function (response) {
            ref.dados = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      salvar : function () {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Salvar alterações?',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               if (ref.dadosEdit.cpf.length < 14) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'CPF inválido!'
                  });
      
                  return
               } else if (ref.dadosEdit.email.length < 5) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Digite ao menos 5 caracteres no email!'
                  });
      
                  return
               } else if (ref.dadosEdit.rua.length < 5) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Digite ao menos 5 caracteres na rua!'
                  });
      
                  return
               } else if (ref.dadosEdit.numero.length < 1) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Digite ao menos 1 caractere no número!'
                  });
      
                  return
               } else if (ref.dadosEdit.bairro.length < 5) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Digite ao menos 5 caracteres no bairro!'
                  });
      
                  return
               } else if (ref.dadosEdit.cep.length < 5) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Digite ao menos 5 caracteres no CEP!'
                  });
      
                  return
               } else if (ref.dadosEdit.telefone.length != 15) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'WhatsApp inválido!'
                  });
      
                  return
               }
               
               ref.$store.dispatch('alternarTelaCarregamento', true)
      
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'user/edituser',
                  params: {
                     id: ref.dados.user.id,
                     cpf: ref.dadosEdit.cpf,
                     email: ref.dadosEdit.email,
                     rua: ref.dadosEdit.rua,
                     numero: ref.dadosEdit.numero,
                     bairro: ref.dadosEdit.bairro,
                     cep: ref.dadosEdit.cep,
                     pontoRef: ref.dadosEdit.pontoDeReferencia.trim() == '' ? 'Desconhecido': ref.dadosEdit.pontoDeReferencia,
                     telefone: ref.dadosEdit.telefone,
                     nomeContato: ref.dadosEdit.nomeContato.trim() == '' ? 'Desconhecido': ref.dadosEdit.nomeContato
                  }
               }).then(function () {
                  ref.toggleEditar = false
                  ref.buscarUsuario()
      
                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Alterações salvas!'
                  });
               }).catch(function (error) {
                  if (error.response != undefined) {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
		}
   },
   created() {
      this.buscarUsuario()
   }
}

</script>